import React from 'react';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonItem, IonList, IonTitle, IonIcon, IonToolbar, IonSearchbar } from '@ionic/react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { IonInput, IonCheckbox, IonSelect, IonSelectOption } from '@ionic/react';
import { IonRadio, IonRadioGroup, IonImg, IonButton } from '@ionic/react';

import Box from '@mui/material/Box';
import { DataGrid, GridRowsProp, GridColDef, GridColumnGroupingModel, GridRowModesModel, GridRowModes, GridActionsCellItem } from '@mui/x-data-grid';

import { useParams } from 'react-router';
import { useCallback, useEffect, useState } from 'react'
import { search, lockClosed, add } from 'ionicons/icons';

import './Page.css';

const Factura: React.FC = () => {
  interface StudentFile {
    id:number;
    rut:string;
    apellidos:string;
  }

  interface StudentMovements {
    fecha: Date | undefined;
    comprobante: string;
    glosa: string;
    debe: number;
    haber: number; 
    saldo: number;
    totalDeudas:number;
    ingresoAlimentacion:number; 
    deudaAlimentacion:number;
    ingresoLavado:number;
    deudaLavado:number;
    ingresoVestuario:number;
    deudaVestuario:number;
    ingresoTextoEstudio:number;
    deudaTextoEstudio:number;
    ingresoBienesMuebles:number;
    deudaBienesMuebles:number;
  }

  const studentFile = [
    {rut:'11647580-4', apellidos:'Francisco Schatzke Fomigli'},
    {rut:'77235028-7', apellidos:'Capturactiva SPA'}
  ]

  const studentMovements = [
    {
        id:1,
        fecha: new Date("2021-02-22"),
        comprobante: "E-319",
        glosa: 'LAVANDERIA',
        debe: 0,
        haber: 11140,
        saldo: 0,
        totalDeudas:0,
        ingresoAlimentacion:0, 
        deudaAlimentacion:0,
        ingresoLavado:0, 
        deudaLavado:11140,
        ingresoVestuario:0, 
        deudaVestuario:11140,
        ingresoTextoEstudio:0,
        deudaTextoEstudio:0,
        ingresoBienesMuebles:0,
        deudaBienesMuebles:0
      },
    {
      id:2,
      fecha: new Date("2021-02-22"),
      comprobante: "T-69",
      glosa: 'ALIMENTACION',
      debe: 0,
      haber: 337957,
      saldo: 0,
      totalDeudas:0,
      ingresoAlimentacion:0, 
      deudaAlimentacion:0,
      ingresoLavado:0, 
      deudaLavado:11140,
      ingresoVestuario:0, 
      deudaVestuario:0,
      ingresoTextoEstudio:0,
      deudaTextoEstudio:0,
      ingresoBienesMuebles:0,
      deudaBienesMuebles:0
    }
  ]
  const setStudentSums= ()=>{
    let saldoAnterior=0
    studentMovements.forEach(movement=>{
      movement.saldo=movement.debe - movement.haber
      movement.totalDeudas=movement.debe + movement.haber + saldoAnterior
      saldoAnterior=movement.debe + movement.haber 
    })
  }

  setStudentSums()
  console.log("studentMovements:", studentMovements)

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

  const [isSearchVisible, setIsSearchVisible]= useState<boolean>();

  const rows: GridRowsProp = studentMovements
  const columns: GridColDef[] = [
    {field:'fecha', headerName:'Fecha', type: 'date', width: 150, editable: true},
    {field:'comprobante', headerName:'Nº comp.', width: 100, editable: true},
    {field:'glosa', headerName:'Glosa', width: 250, editable: true},
    {field:'debe', headerName:'Debe', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {field:'haber', headerName:'Haber', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {field:'saldo', headerName:'Saldo', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {field:'totalDeudas', headerName:'Total deudas', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {field:'ingresoAlimentacion', headerName:'Ingreso', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {field:'deudaAlimentacion', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {field:'ingresoLavado', headerName:'Ingreso', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {field:'deudaLavado', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {field:'ingresoVestuario', headerName:'Ingreso', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {field:'deudaVestuario', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {field:'ingresoTextoEstudio', headerName:'Ingreso', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {field:'deudaTextoEstudio', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {field:'ingresoBienesMuebles', headerName:'Ingreso', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {field:'deudaBienesMuebles', headerName:'Deuda', width: 100, editable: true, align:'right', headerAlign: 'right', valueFormatter: (value) => currencyFormatter.format(Number(value))},
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',

      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<search />}
              key={1}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              // onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<search />}
              key={2}
              label="Cancel"
              className="textPrimary"
              // onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<search />}
            key={3}
            label="Edit"
            className="textPrimary"
            // onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<search />}
            key={4}
            label="Delete"
            // onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      }
    }
  ]

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'alimentacion',
      description: 'Information about the character',
      headerName: 'Alimentación',
      headerAlign: 'center',
      children: [{ field: 'ingresoAlimentacion' }, { field: 'deudaAlimentacion' }]
    },
    {
      groupId: 'lavado',
      description: 'Information about the character',
      headerName: 'Lavado',
      headerAlign: 'center',
      children: [{ field: 'ingresoLavado' }, { field: 'deudaLavado' }]
    },
    {
      groupId: ' vestuario',
      description: 'Information about the character',
      headerName: 'Vestuario y equipo',
      headerAlign: 'center',
      children: [{ field: 'ingresoVestuario' }, { field: 'deudaVestuario' }]
    },
    {
      groupId: ' textos',
      description: 'Information about the character',
      headerName: 'Textos de estudio',
      headerAlign: 'center',
      children: [{ field: 'ingresoTextoEstudio' }, { field: 'deudaTextoEstudio' }]
    },
    {
      groupId: ' bienes',
      description: 'Information about the character',
      headerName: 'Bienes y muebles',
      headerAlign: 'center',
      children: [{ field: 'ingresoBienesMuebles' }, { field: 'deudaBienesMuebles' }]
    },

  ];

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  });

  const [results, setResults] = useState([...studentFile]);

  const [rut, setRut] = useState<string>()
  const [apellidos, setApellidos] = useState('')

  const handleInput = (ev: Event) => {
    let query = '';
    const target = ev.target as HTMLIonSearchbarElement;
    if (target) query = target.value!.toLowerCase();

    if(query.length==0){
      setResults(studentFile.filter((d) => d.apellidos.toLowerCase().indexOf('Schatzke') > -1));
    }else{
      setResults(studentFile.filter((d) => d.apellidos.toLowerCase().indexOf(query) > -1));
    }
  };

  const name='Ingreso factura compras'
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState<boolean>();

  const handleItemSearchClick= (e:any)=>{
    setRut(e.rut);
    setApellidos(e.apellidos)

    setIsSearchVisible(!isSearchVisible)
  }

  const handleSearchClick = ()=>{
    setResults(studentFile.filter((d) => d.apellidos.toLowerCase().indexOf('Schatzke') > -1));
    setIsSearchVisible(!isSearchVisible)
  }

  const validate = (ev: Event) => {
    const value = (ev.target as HTMLInputElement).value;

    setIsValid(undefined);

    if (value === '') return;

    // validateEmail(value) !== null ? setIsValid(true) : setIsValid(false);
  };

  const markTouched = () => {
    setIsTouched(true);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{name}</IonTitle>
        </IonToolbar>

        {isSearchVisible &&
          <IonToolbar>
            {isSearchVisible &&
              <IonSearchbar placeholder="Búsqueda de alumnos..." debounce={1000} onIonInput={(ev) => handleInput(ev)}></IonSearchbar>
            }

            {isSearchVisible &&
              <IonList>
                {results.map((result, index) => (
                  <IonItem onClick={() =>handleItemSearchClick(result)} button={true} key={index}>{result.apellidos}</IonItem>
                ))}
              </IonList>
            }          
          </IonToolbar>
        }
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">{name}</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div className="container-evento">
          <div className="ion-padding">
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="ion-padding">
                  <IonRow>
                    <IonCol size="3">
                      <IonInput
                          value={rut}
                          className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                          type='text'
                          labelPlacement="floating"
                          clearInput={false}
                          label='RUT'
                          placeholder='ej:11647580-4'
                          onIonInput={(event) => validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">
                          <IonIcon slot="end" icon={search} aria-hidden="true" onClick={handleSearchClick}></IonIcon>
                          {/* <IonButton fill="clear" slot="end" aria-label="Show/hide">
                            <IonIcon slot="icon-only" name={eye} aria-hidden="true"></IonIcon>
                          </IonButton> */}

                        </IonInput>
                    </IonCol>
                    <IonCol size="4">
                        <IonInput
                            value={apellidos}
                            className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                            type='text'
                            labelPlacement="floating" 
                            label='Nombres y apellidos'
                            readonly={true}
                            placeholder='ej:5643245'
                            onIonInput={(event) => validate(event)}
                            onIonBlur={() => markTouched()}
                            helperText='Ingrese factura de gastos válida'
                            errorText="Número factura incorrecta">
                        </IonInput>
                    </IonCol>
                  </IonRow>                  
                </IonCol>
                <IonCol size="12" className="ion-padding">
                  <IonRow>
                    <IonCol size="1.5">
                      <IonInput 
                          className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                          type='date'
                          labelPlacement="floating" 
                          label='Fecha'
                          placeholder='ej:5643245'
                          onIonInput={(event) => validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">
                        </IonInput>
                    </IonCol>

                    <IonCol size="1.5">
                      <IonInput 
                        className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                        type='text'
                        labelPlacement="floating" 
                        label='Nº comprobante'
                        placeholder='ej:5643245'
                        onIonInput={(event) => validate(event)}
                        onIonBlur={() => markTouched()}
                        helperText='Ingrese factura de gastos válida'
                        errorText="Número factura incorrecta">
                      </IonInput>
                    </IonCol>

                    <IonCol size="3">
                      <IonInput 
                          className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                          type='text'
                          labelPlacement="floating" 
                          label='Glosa'
                          placeholder='ej:5643245'
                          onIonInput={(event) => validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">
                        </IonInput>
                    </IonCol>

                    <IonCol size="1.5">
                      <IonInput 
                          className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                          type='number'
                          labelPlacement="floating" 
                          label='DEBE'
                          placeholder='ej:5643245'
                          onIonInput={(event) => validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">
                        </IonInput>
                    </IonCol>

                    <IonCol size="1.5">
                      <IonInput 
                          className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                          type='number'
                          labelPlacement="floating" 
                          label='HABER'
                          placeholder='ej:5643245'
                          onIonInput={(event) => validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">
                        </IonInput>
                    </IonCol>

                    <IonCol size="2">
                      <IonInput 
                          className={`${isValid && 'ion-valid'} ${isValid === false && 'ion-invalid'} ${isTouched && 'ion-touched'}`} 
                          type='number'
                          labelPlacement="floating" 
                          label='SALDO'
                          placeholder='ej:5643245'
                          onIonInput={(event) => validate(event)}
                          onIonBlur={() => markTouched()}
                          helperText='Ingrese factura de gastos válida'
                          errorText="Número factura incorrecta">
                        </IonInput>
                    </IonCol>

                    <IonCol size="1">
                      <IonButton type="submit">Grabar</IonButton>
                    </IonCol>
                  </IonRow>
                </IonCol>

                <IonCol size="12">
                  <IonRow>
                    <IonCol size="12">
                      <DataGrid 
                        rows={rows} 
                        columns={columns} 
                        columnGroupingModel={columnGroupingModel} 
                        editMode="row"
                        rowModesModel={rowModesModel}
                      />
                    </IonCol>                    
                  </IonRow>

                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
    </IonPage>

  );
};

export default Factura;
